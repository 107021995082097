import React, { useEffect, useState } from "react";
import axios, { AxiosError } from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { Button, Col, Container, Form, Row } from "react-bootstrap";


function FileList(props) {

  const [displayData, setDisplayData] = useState([]);
  const [folderName, setFolderName] = useState(props.folderName);
  const [showCivil, setShowCivil] = useState(props.showCivil);

 

  const columns = [
  {
    dataField: 'name',
    text: 'File Name',
    formatter: (value, row) => {
      return value.replace('.pcm', '.wav');

    }
  }, 
  {
    dataField: 'size',
    text: 'File Size',
    formatter: (value, row) => {
      return (value / (1024*1024)).toFixed(2) + ' Mb';

    }
  },
  {
    dataField: 'time',
    text: 'Last Modified',
    formatter: (value, row) => {
      return moment.unix(value / 1000).utc().format("YYYY-MM-DD HH:mm");

    }
  },
    {
      dataField: "option",
      text: "Action",
      formatter: (cell, row, rowIndex, option) => {
        return (
          <Button variant="secondary" size="sm"
            onClick={() => {
              downloadFile(row.name);
              
            }
          }
          >
            Download
          </Button>
        );
      }
  }
  ];

  

  const sortAsc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return 1; }
      if (b[field] > a[field]) { return -1; }
      return 0;
    })
  }

  const sortDesc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return -1; }
      if (b[field] > a[field]) { return 1; }
      return 0;
    })
  }


  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(`clicked on row with index: ${rowIndex}`);

    }
  };

  useEffect(() => {
    updateMessageData();
  }, []);

  const rowStyle = (row, rowIndex) => {
    return {
      fontFamily: "monospace"
    };
  };


  const buildWaveHeader = (numFrames) => {
    var numFrames = numFrames;
    var numChannels = 1;
    var sampleRate = 8000;
    var bytesPerSample = 2;
    var blockAlign = numChannels * bytesPerSample;
    var byteRate = sampleRate * blockAlign;
    var dataSize = numFrames * blockAlign;

    var buffer = new ArrayBuffer(44);
    var dv = new DataView(buffer);

    var p = 0;

    function writeString(s) {
        for (var i = 0; i < s.length; i++) {
            dv.setUint8(p + i, s.charCodeAt(i));
        }
        p += s.length;
    }

    function writeUint32(d) {
        dv.setUint32(p, d, true);
        p += 4;
    }

    function writeUint16(d) {
        dv.setUint16(p, d, true);
        p += 2;
    }

    writeString('RIFF');              // ChunkID
    writeUint32(dataSize + 36);       // ChunkSize
    writeString('WAVE');              // Format
    writeString('fmt ');              // Subchunk1ID
    writeUint32(16);                  // Subchunk1Size
    writeUint16(1);                   // AudioFormat
    writeUint16(numChannels);         // NumChannels
    writeUint32(sampleRate);          // SampleRate
    writeUint32(byteRate);            // ByteRate
    writeUint16(blockAlign);          // BlockAlign
    writeUint16(bytesPerSample * 8);  // BitsPerSample
    writeString('data');              // Subchunk2ID
    writeUint32(dataSize);            // Subchunk2Size

    return buffer;
}

   const downloadFile = (name) => {

    var token = localStorage.getItem('user-token');

    var url = 'https://jlogger.net/api/files/' + folderName + "/" + name;


    axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,

        },
        responseType: 'arraybuffer'
   
    }).then((response) => {
       
       var samples = response.headers["content-length"];

       var header = buildWaveHeader(samples/2);

       const url = URL.createObjectURL(new Blob([header, response.data]))

       const newName = name.replace('.pcm', '.wav');

       // Create a temporary <a> element to trigger the download
       const tempLink = document.createElement("a");
       tempLink.href = url;
       tempLink.setAttribute(
         "download",
         `${newName}`
       ); // Set the desired filename for the downloaded file

       // Append the <a> element to the body and click it to trigger the download
       document.body.appendChild(tempLink);
       tempLink.click();

       // Clean up the temporary elements and URL
       document.body.removeChild(tempLink);
       window.URL.revokeObjectURL(url);
    });

  }

  const updateMessageData = () => {

    var token = localStorage.getItem('user-token');

    setDisplayData([]);

    var url = 'https://jlogger.net/api/listFiles/' + folderName;

    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }

    })
      .then(res => {

        
        if(showCivil){

          setDisplayData(sortDesc(res.data, 'time'))

        }else{

          setDisplayData(sortDesc(res.data.filter((element) => String(element.name).startsWith('mil_')), 'time'))

        }
        
        

      })

      .catch(error => {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if (error.response.status == '401') {

            alert('Session has timed out');

          } else {

            alert('Error getting folder data ' + error.response.status);
          }


        } else {

          alert('Error getting folder data');
        }
      });

  }


  return (

    <React.Fragment>
      <>
        <div >
          <BootstrapTable
            bootstrap4
            keyField="name"
            data={displayData}
            rowStyle={rowStyle}
            columns={columns}
            hover={true}
            rowEvents={rowEvents}
            condensed={true}           
          />
        </div>
      </>
    </React.Fragment>
  )
}
export default FileList;
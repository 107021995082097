import React, { useEffect, useState } from "react";
import axios, { AxiosError } from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import FileList from './FileList';



function FolderList(props) {

  const [displayData, setDisplayData] = useState([]);
  const [showCivil, setShowCivil] = useState(props.showCivil);

  const columns = [{
    dataField: 'name',
    text: 'Folder'
   },
   {
    dataField: 'time',
    text: 'Last Modified',
    hidden: true,
    formatter: (value, row) => {
      return moment.unix(value / 1000).utc().format("YYYY-MM-DD HH:mm");

    },
  }
  ];

  
 
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(`clicked on row with index: ${rowIndex}`);
     
    }
  };

  useEffect(() => {
    updateMessageData();
  }, []);


 
  const rowStyle  = (row, rowIndex) => {
    return {
      fontFamily: "monospace"
      };
  };

  const expandRow = {
    renderer: row => (
      <React.Fragment>
  
        <div>
          <FileList folderName={row.name} showCivil={showCivil}> </FileList>
        </div>
      </React.Fragment>
    )
  };
  
  const sortAsc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return 1; }
      if (b[field] > a[field]) { return -1; }
      return 0;
    })
  }

  const sortDesc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return -1; }
      if (b[field] > a[field]) { return 1; }
      return 0;
    })
  }

  const handlCivilChange = (event) => {
    setShowCivil(showCivil == true? false : true);
  }


  const updateMessageData = () => {

    var token = localStorage.getItem('user-token');

    setDisplayData([]);

    var url = 'https://jlogger.net/api/listFiles/root';

    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }

    })
      .then(res => {
        
        setDisplayData(sortDesc(res.data, 'time'))

      })

      .catch(error => {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if(error.response.status == '401'){

            alert('Session has timed out');

          }else{

            alert('Error getting flight data ' + error.response.status );
          }
          

        } else {

          alert('Error getting message data');
        }
      });

  }

 
  return (
    
    <React.Fragment>
    <>
   
    <div class="mb-3">
     <Form onSubmit={e => {e.preventDefault()}}>
      <Row style={{width: '750px'}} className="align-items-center">
        <Col xs="auto">
        <Form.Check type="switch" id="custom-switch" label="Include civil" checked={showCivil} value={showCivil} onClick={handlCivilChange}
        />
        </Col>
      </Row>
    </Form>
    </div>

    <div >
      <BootstrapTable 
        bootstrap4
        keyField="name"
        data={displayData}
        rowStyle={ rowStyle }   
        columns={columns}
        hover={true}
        rowEvents={rowEvents}
        condensed ={true}
        expandRow={expandRow}
        />
    </div>
    </>
    </React.Fragment>
  )
}
export default FolderList;